
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import CatalogueLocalGrid from "@/components/controls/catalogueGrid/CatalogueLocalGrid.vue";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    CatalogueLocalGrid,
    TextControl,
  },
  props: {
    saveModelCallback: { required: true },
  },
  mixins: [mountComponentMixin],
  methods: {
    getCreateData() {
      return { id: `${this.dataGridItems.length + 1}` };
    },
    // Перевод из строки в json-формат
    parse(value: any) {
      if (value) return JSON.parse(value);
    },
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dataGridItems = [];
      this.saveData();
    },
    save() {
      this.dialog().save();
    },
    saveData() {
      // Перевод из json-формата в строку
      this.dialog().createEditModel.data = JSON.stringify(this.dataGridItems);
    },
    onShown(model: any) {
      // Перевод из строки в json-формат при создании компонента
      if (model.data) {
        this.dataGridItems = this.parse(model.data);
      }
    },
  },
  data() {
    return {
      columns: [
        new TextColumnConfig({
          dataField: "id",
          caption: "Идентификатор",
          readOnly: true,
          validationRules: [requiredRule],
          format: ""
        }),
        new TextColumnConfig({
          dataField: "value",
          caption: "Значение",
          validationRules: [requiredRule],
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        height: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        mode: "row",
      }),
      dataGridItems: [],
    };
  },
});
